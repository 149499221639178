<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container popup-no-padding-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-myclass-d02" :style="item.lrnObjTyCd === lrnObjTyCds.Scorm? {'width':'1920px'}:{}">
      <!-- popup > popup-inner -->
      <div class="popup-inner" :style="item.lrnObjTyCd === lrnObjTyCds.Scorm? {'padding':'30px 40px' , 'height':'100%'}:{}">
        <div>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="learning-view-container" :style="item.lrnObjTyCd === lrnObjTyCds.Scorm? {  'height':'100%'}:{}">
              <div class="player player-vod">
                <div class="player-container">
                  <!-- player-inner -->
                  <div class="player-inner">
                    <!-- player-viewport -->
                    <div class="player-viewport" :style="item.lrnObjTyCd === lrnObjTyCds.Scorm? {'overflow-y':'scroll', 'height':'100%'}:{}">
                      <!-- player-video -->
                      <LearnVideo v-if="item.lrnObjTyCd === lrnObjTyCds.MP3 || item.lrnObjTyCd === lrnObjTyCds.MP4"
                                  :item="item"
                                  :cms="cms"
                                  :memory-limit="memoryLimit"
                                  :use-cold-call="useColdCall"
                                  :uuid="uuid"
                      />
                      <LearnPdf v-else-if="item.lrnObjTyCd === lrnObjTyCds.PDF"
                                :cms="cms"
                                :uuid="uuid"
                      />

                      <LearnHtml v-else-if="item.lrnObjTyCd === lrnObjTyCds.HTML"
                                 :cms="cms"
                                 :uuid="uuid"
                      />
                      <LearnScorm v-else-if="item.lrnObjTyCd === lrnObjTyCds.Scorm"
                                 :item="item"
                                 :cms="cms"
                                 :uuid="uuid"
                      />
                    </div>
                    <!-- //player-viewport -->
                  </div>

                  <!-- player-details -->
                  <div class="player-details">
                    <div class="detail-row">
                      <div class="detail-contents">
                        <p class="subtitle">{{ item.lrnObjTyNm === 'MP4' ? '동영상' : item.lrnObjTyNm }}</p>
                        <h3 class="title">{{ item.objNm }} </h3>
                        <div class="metadata-container metadata-divider">
                          <div v-if="item.chapNm" class="metadata"><span class="text">{{ item.chapNm }}</span></div>
                        </div>
                      </div>
<!--                      <div class="detail-actions">-->
<!--                        <a href="javascript:" class="text-link">콘텐츠 오류신고</a>-->
<!--                      </div>-->
                    </div>
                    <div v-if="item.lrnObjTyCd === lrnObjTyCds.PDF" class="detail-row detail-file">
                      <a href="javascript:" class="file-link kb-btn kb-btn-outline rounded-lg" @click.stop="downloadFile">
                        <span class="filename">{{ `${item.objNm}.pdf` }}</span>
                        <i class="icon-download"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close" @click="closeModal">
        <button class="kb-btn-popup-close"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>
import {excuteApps_webview, getItem, getUrl, lengthCheck, timestampToDateFormat, winOpen} from '@/assets/js/util';
import LearnVideo from '@/components/learn/window/LearnVideo';
import {enableLearn, lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import LearnPdf from '@/components/learn/window/LearnPdf';
import LearnHtml from '@/components/learn/window/LearnHtml';
import LearnScorm from '@/components/learn/window/LearnScorm';
import {guid} from '@/assets/js/ui.init';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {isInApp} from "@/assets/js/navigationUtils";
import ApiService from "@/assets/js/api.service";

export default {
  name: 'LearnCms',
  components: {LearnPdf, LearnVideo,LearnHtml,LearnScorm},
  props: {
    active: Boolean,
    item: Object,
    cms: Object,
    memoryLimit: {
      type: Number,
      default: 0
    },
    useColdCall: Boolean,
    lrnPsbltHrDiv: String,
  },
  emits: ['update:active', 'renewEnd'],
  setup(props, {emit}) {
    const pdfUrl = computed(() => {
      return getUrl(props.cms.etnUrl);
    });

    const closeModal = () => {
      emit('renewEnd');
      emit('update:active', false);
    }

    const isVacation = ref(false);

    const checkVacation = () =>{
      ApiService.get('/v1/app/auth/vacation').then(res => {
        if(lengthCheck(res)) {
          if(getItem(res) > 0){
            isVacation.value = true;
          } else {
            isVacation.value = false;
          }
        }
      });
    }
    checkVacation();

    const checkInterval = props.lrnPsbltHrDiv === '02' || props.lrnPsbltHrDiv === '03' ?
        setInterval(() => {if (!isVacation.value &&!enableLearn(props.lrnPsbltHrDiv, true)) { closeModal(); }}, 5 * 1000) :
        0
    ;

    const downloadFile = () => {
      winOpen(pdfUrl.value, '_blank');
    };

    onUnmounted(() => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }

      //isinapps
      console.log("apps check unmount", isInApp);
      if(isInApp){
        let jobJson = {
          cmd : "setDevicePullToRefresh",
          enable : "true"
        }
        excuteApps_webview(JSON.stringify(jobJson));
      }

    });


    onMounted(()=>{

      console.log("apps check mount", isInApp);
      if(isInApp){
        let jobJson = {
          cmd : "setDevicePullToRefresh",
          enable : "false"
        }
        excuteApps_webview(JSON.stringify(jobJson));
      }
    });

    return {
      uuid: guid(12),
      closeModal,
      timestampToDateFormat,
      lrnObjTyCds,
      downloadFile
    }
  }
};
</script>
