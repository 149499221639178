<template>
          <div v-if="hrd && (hrd.title || hrd.desc)" class="chat-container" style="z-index:-100" >
            <ul class="chat-messages" style="padding:10px !important;">
              <li class="incoming-message message">
                <div class="message-content">
                  <template v-if="hrd.desc">
                    <!-- [!DEV] message-bubble is-active 영역 확장 활성화 클래스 -->
                    <div class="message-bubble is-active">
                      <!-- [!DEV] collapse is-active 상세보기 활성화 -->
                      <div class="message-collapse kb-collapse" :class="{'is-active':toggleScenario}">
                        <div class="message-collapse-toggle kb-collapse-toggle">
                          <a href="javascript:" class="kb-btn-toggle" @click="toggleScenario=!toggleScenario">
                            <div class="message-author">
                              <div class="author-avatar">
                                <img src="../../../assets/lxp/images/common/avatar_01.svg">
                              </div>

                            </div>
                            <span class="text">시나리오 보기</span>
                            <strong class="text">{{ toggleScenario ? '접기' : '열기' }} <i class="icon-arrow"></i></strong>
                          </a>
                        </div>
                        <div class="message-collapse-target kb-collapse-target kb-scenario-wrapper">
                          <div class="kb-collapse-contents"  v-html="`[기본 시나리오]<br>`+hrd.title" ></div>
                          <div class="kb-collapse-contents" v-html="hrd.desc"></div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </ul>
          </div>
<!--          <div class="position-relative" style="overflow-x: clip;">-->
<!--&lt;!&ndash;            <div class="player">&ndash;&gt;-->
<!--&lt;!&ndash;              <HrdCloudRoadmapPlayerButton :crse="hrd" />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
          <div  style="position: relative;width:100%" >
            <iframe v-if="hrd"
                    ref="iframeViewer"
                    :src="hrd.etnUrl"
                    :data-lrner-id="session.lrnerId"
                    width="100%"
                    height="1000px"
                    title=""
            />
          </div>
<!--        </div>-->
<!--        <div class="view-details">-->
<!--          <HrdPlayerDetails v-if="hrd" :hrd="hrd" :funcs="detailFuncs" />-->
<!--        </div>-->
<!--        <div class="view-comment">-->
<!--          <HrdCloudComment v-if="hrd" :effect-nav="effectNav" :objSn="hrd.objSn" />-->
<!--        </div>-->
<!--      </div>-->

    <HrdCloudStopAlert v-if="scoreBoard.zeroScoreDisplay"
                       v-model="scoreBoard.zeroScoreDisplay"
                       title="전산실습Easy 학습 안내"
                       type="easy"
                       :func="scoreBoard.toggle">
      <template v-slot:content>
        <p class="text text-sm">가이드에서 제시된 실습이 아닌 <span style="color:red;">비정상 실습진행</span>으로<br>
          <span style="color:red;">학습완료 및 마일리지가 인정되지 않을 수 있습니다. 정상적인 방법으로 실습을 진행 해 주세요</span></p>
      </template>
    </HrdCloudStopAlert>


    <HrdCloudStopAlert v-if="scoreBoard.zeroScoreAbort"
                       v-model="scoreBoard.zeroScoreAbort"
                       title="전산실습Easy 학습 안내"
                       type="easyAbort"
                       :func="scoreBoard.reStart">
      <template v-slot:content>
        <p class="text"><span style="color:red;">비정상 실습진행</span>으로 더이상 학습하실 수 없습니다.<br>
          처음부터 다시 정상적인 방법으로 실습을 진행 해 주세요</p>
      </template>
    </HrdCloudStopAlert>
    <LxpMessage v-model="localMessage.show" :options="localMessage" />
<!--    <HrdCloudHistory v-if="hrd && historyModal" v-model="historyModal" :title="hrd.objNm" :duration="hrd.cttSz" :items="histories" :func="showLatestHistory" :ctt-ty-cd-dcd="hrd.cttTyCdDcd" />-->
<!--    <HrdCloudHistoryDtlModal v-if="latestHistoryModal" v-model="latestHistoryModal" v-model:lrn-obj-dtl-dist-sn="lrnObjDtlDistSn" :year="new Date().getFullYear()" :month="new Date().getMonth()+1" />-->
<!--    <HrdCloudShareModal v-if="hrd && shareModal" v-model="shareModal" :data="hrd" />-->
<!--    <HrdCloudOpinionModal v-if="hrd && opinionModal" v-model="opinionModal" :cttMstSn="hrd.objSn" :title="hrd.objNm" />-->

    <!--    <HrdCloudLrnComplete v-model="completeModal"  v-model:hrdObj="hrd"/>-->
</template>
<style scoped>
.lxp-layout .kb-scenario-wrapper {
  overflow-y: unset !important;
}
</style>
<script>
import {goBack} from '@/assets/js/modules/common/common';
import {getItem, getVideoUrl, isSuccess, lengthCheck, setParams, stringCheck} from '@/assets/js/util';
import {computed, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {useStore} from 'vuex';
// import HrdCloudComment from '@/components/hrdcloud/HrdCloudComment';
// import HrdCloudShareModal from '@/components/hrdcloud/HrdCloudShareModal';
import {
  // darkMode,
  eventCdDcds,
  generateUUID,
  // getTop1CategoryByLevel,
  getUserProgress,
  handleActions,
  // insertVodLearnProgress,
  // lightMode,
  // makeTags,
  pageView,
  toggleConcentrationMode,
} from '@/assets/js/modules/hrd/hrd-common';
import {getToken} from '@/assets/js/localstorage.service';
import {TOKEN_KEY} from '@/store/modules/auth/auth';
// import HrdCloudOpinionModal from '@/components/hrdcloud/HrdCloudOpinionModal';
import {useRoute, useRouter} from 'vue-router';
// import {ACT_GET_HRDCLOUD_DETAIL} from '@/store/modules/prep/prep';
// import HrdCloudLrnComplete from '@/components/hrdcloud/HrdCloudLrnComplete.vue';
// import HrdPlayerDetails from '@/components/hrdcloud/pages/HrdPlayerDetails.vue';
// import HrdCloudHistory from '@/components/hrdcloud/HrdCloudHistory.vue';
// import HrdCloudHistoryDtlModal from '@/components/history/HrdCloudHistoryDtlModal.vue';
// import HrdCloudRoadmapPlayerButton from '@/components/hrdcloud/HrdCloudRoadmapPlayerButton';
import {useAlert} from '@/assets/js/modules/common/alert';
import HrdCloudStopAlert from "@/components/hrdcloud/HrdCloudStopAlert.vue";
import LxpMessage from "@/components/common/LxpMessage.vue";
import {insertLearnProgressLog} from "@/assets/js/modules/learn/learn-common";
import {ACT_INSERT_LEARN_PROGRESS_RESULTS} from "@/store/modules/learn/learn";

export default {
  name: 'LearnScorm',
  components: {
    LxpMessage,
    // HrdPlayerDetails,
    HrdCloudStopAlert,
    },
  props: {
    cms: Object,
    item:Object,
    uuid: String,
    // effectNav: {
    //   required: false,
    //   type: Number,
    //   default: 0
    // },
  },
  setup(props) {
    const store = useStore(), route = useRoute(), {hideLoading, showLoading} = useAlert(),
        isMobile = computed(() => store.state.auth.isMobile),
        session = computed(() => store.state.auth.session),
        targetMode = computed(() => process.env.NODE_ENV === 'production' ? "PRD" : "DEV");
    // const concentrationModeText = computed(() => store.state.hrdcloud.mode === 'dark' ? '집중모드 켜짐' : '집중모드 꺼짐');
    showLoading();
    const lrnObjDtlDistSn = computed(() => route.params.lrnObjDtlDistSn),
        // mode = computed(() => route.params.mode || 'demo'),
        mode = ref('uebung'),
        shareModal = ref(false), historyModal = ref(false), opinionModal = ref(false), latestHistoryModal = ref(false),
        iframeViewer = ref(null),
        coldcall = ref(false),
        user = reactive({ progRsltSn: 0, maxPlaySec: 0, lastPlaySec: 0, cmptnYn: 'N', mdfcnDt: null, regDt: null }),
        token = getToken(TOKEN_KEY),
        completeModal = ref({
          show: false,
          feedback: false
        });
    const progRsltSn = ref(0);
    const toggleScenario = ref(false);
    const hrd = computed(() => props.cms);

    const objectParams = computed(() => {
      return {
        distCrseSn: props.cms.distCrseSn,
        distChapSn: props.cms.distChapSn,
        lrnObjDtlDistSn: props.cms.lrnObjDtlDistSn,
        objSn: props.cms.objSn,
        division: 'scorm',
      };
    });

    const histories = ref([]);
    const uuid = ref(null);
    // const completeActive = ref(true);

    const updatingLog = ref(false);
    const progRsltInit =  async () => {

      const res = await store.dispatch(`learn/${ACT_INSERT_LEARN_PROGRESS_RESULTS}`, {...objectParams.value});
      if(isSuccess(res) && res.progRsltSn > 0) {
        progRsltSn.value = res.progRsltSn;
        // 여기서 콜드콜 현재 조회
        // if(res.lastPlaySec > 0 && res.lastPlaySec !== parseInt(meta.duration)){
      }
    }


    const scoreBoard = reactive({
      zeroScoreCnt:0,
      totCumScore:0,
      totCurScore:0,
      zeroScoreDisplay:false,
      zeroScoreAbort:false,
      toggle: (bool) =>{
        scoreBoard.zeroScoreDisplay = bool;
      },
      reStart: () => {
        location.reload();
      }
    });

    const getUUID = async () => {
      let id = null;
      if (!uuid.value) {
        id = await generateUUID();
        if (id && id.uuid) uuid.value = id.uuid;
      } else {
        uuid.value = id.uuid;
      }
    };

    const getEasyUrl = (data) => {
      if (data) {
        const playSrc = getVideoUrl(session.value.itn, data);
        let queryString = `&kbtoken=${encodeURIComponent(token)}&lrnerId=${encodeURIComponent(session.value.lrnerId)}&progSesnKey=${encodeURIComponent(uuid.value)}&distCrseSn=${encodeURIComponent(data.distCrseSn)}&lrnObjDtlDistSn=${encodeURIComponent(data.lrnObjDtlDistSn)}&objSn=${encodeURIComponent(data.objSn)}&mode=${mode.value}&targetMode=${targetMode.value}`;
        if (stringCheck(playSrc)) {
          const src = `${playSrc}:${mode.value}${queryString}`;
          return src;
        }
      }
      return null;
    };

    const getHrdCloudDetail = async () => {

      pageView(hrd);
      await getUUID();
      console.log("hrd",hrd.value);
      hrd.value.etnUrl = getEasyUrl(hrd.value);
      const userProgressRes = await getUserProgress(hrd);
      console.log(userProgressRes);
      if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      hideLoading();

      // store.dispatch(`prep/${ACT_GET_HRDCLOUD_DETAIL}`, {lrnObjDtlDistSn: lrnObjDtlDistSn.value}).then(async (res) => {
      //   if (res && res.items.length > 0) {
      //     hrd.value = res.items.map(x => {
      //       return {
      //         ...getTop1CategoryByLevel(x.cloudKeyLst),
      //         ...x,
      //         tag: makeTags(x.tag)
      //       };
      //     })[0];
      //     pageView(hrd);
      //   }
      //
      //   await getUUID();
      //
      //   hrd.value.etnUrl = getEasyUrl(hrd.value);
      //
      //   const userProgressRes = await getUserProgress(hrd);
      //   if(lengthCheck(userProgressRes)) setParams(user, getItem(userProgressRes));
      //   hideLoading();
      // }).catch(e => {
      //   console.error(e);
      //   hideLoading();
      // });
    };


    const localMessage = reactive({
      show: false,
      center: true,
      title: '',
      text: '학습이 종료되었습니다. 실습과정에서 60%이상 득점하지 못한 경우 학습완료 되지 않습니다.',
      callback: null
    });

    const showMessage = () => {
      localMessage.show = true;
    };

    const receiveMessage = (event) => {

      //lxp, lxpcms 도메인 이외는 무시  cdn.ntruss.com ncloud CDN 추가
      if(event.origin.indexOf("lxp")<0 && event.origin.indexOf("cdn.ntruss.com")<0 && event.origin.indexOf("localhost:8081")<0)
      {
        console.log("not allow domain", event.origin);
        return;
      }

      //localhost 호출 filter
      if(event.data.source=="vue-devtools-backend")return;

      //중복로깅 방지 (callback 확인 후 허용)
      if(updatingLog.value){
        setTimeout(()=>{
          console.log('recall');
          receiveMessage(event)
        }, 1000);
        return;
      }

      try {
        if (event && event.data) {

          try{
            let msg = JSON.parse(event.data);
            console.log("message receive", msg);
            updatingLog.value = true;
            if (msg.complete) {
              if (mode.value === 'uebung') {
                console.log("complete MSG:", msg);
                insertLearnProgressLog(  {
                  progRsltSn: progRsltSn.value,
                  lrnRsltSn: props.cms.lrnRsltSn,
                  progSesnKey: uuid.value,
                  playSpeed: 1,
                  eventCdDcd: eventCdDcds.Ended,
                  eventNm: '학습종료',
                  ...objectParams.value,
                  ...msg,
                },(res) => {

                  updatingLog.value = false;
                  console.log("result", res);
                  // console.log('1 : ',res);
                  console.log("complete" , res.complete);
                  // console.log(completeActive.value);
                  if (res.complete) {
                    completeModal.value.show = true;
                    // completeActive.value = false;
                    if(res.feedbackEnable){
                      completeModal.value.feedback = true;
                    }
                  }else{
                    console.log("complete fail", res);
                    showMessage();
                  }
                });
              }
            }else{


              if (mode.value === 'uebung') {
                    insertLearnProgressLog({
                      progRsltSn: progRsltSn.value,
                      lrnRsltSn: props.cms.lrnRsltSn,
                      progSesnKey: props.uuid,
                      ...objectParams.value,
                      ...msg,
                    }, () => {
                      console.log('update fin');
                      updatingLog.value = false;
                    });


              }


              //무작위 click 방지 매 3회 경고, 17회에 중단
              if(msg.curScore!=undefined){
                if(msg.curScore==0){
                  let alertLog = false;
                  scoreBoard.zeroScoreCnt++;
                  let limitCnt = Math.floor(msg.totalPageCnt * 0.3);
                  limitCnt = limitCnt<7 ? 7 : limitCnt;

                  console.log("zero", limitCnt, scoreBoard.zeroScoreCnt);

                  if(limitCnt<=scoreBoard.zeroScoreCnt ){
                    scoreBoard.zeroScoreAbort = true;
                    alertLog = true;
                  }
                  else if(scoreBoard.zeroScoreCnt%3==0){
                    scoreBoard.zeroScoreDisplay = true;
                    alertLog = true;
                  }

                  if (alertLog){

                    msg.eventCdDcd = "2064005";
                    msg.evnetNm = "중단알림 전송";
                    msg.playSec = 0;

                    insertLearnProgressLog({
                      progRsltSn: progRsltSn.value,
                      lrnRsltSn: props.cms.lrnRsltSn,
                      progSesnKey: props.uuid,
                      ...objectParams.value,
                      ...msg,
                    }, ()=>{
                      console.log('중단알림 발송');
                      updatingLog.value = false;
                    });
                  }


                }
              }

            }
          }
          catch (e) {
            // console.log("message parsing fail", e.message, event.data);
          }

        }
        else{
          console.warn("not data", event);
        }
      } catch (e){
        // POST MESSAGE FAILED
        console.error('logerror : ',e);
      }
    };

    const router = useRouter();
    const fromQuery = computed(() => store.state.common.fromQuery);
    const goBackHrd = () => {
      router.push({name: 'HrdCloud', query: fromQuery.value || {}});
    }

    // const detailFuncs = {
    //   openHistory: (item) => {
    //     handleActions(item).actHistory(historyModal, histories);
    //   },
    //   openShare: (item) => {
    //     handleActions(item).actShare(shareModal);
    //   },
    //   openOpinion: (item) => {
    //     handleActions(item).actOpinion(opinionModal);
    //   }
    // };

    const showLatestHistory = () => {
      latestHistoryModal.value = true;
    };

    getHrdCloudDetail();

    onMounted(() => {
      // darkMode();
      // hrd.value.objSn = null;
      //Easy ObjSn필수값
      hrd.value.objSn = props.cms.distObjSn;
      hrd.value.objNm = props.cms.distObjNm;
      progRsltInit();
      window.addEventListener('message', receiveMessage);
      console.log("add eventlistener!!");
    });

    onBeforeUnmount(() => {
      // lightMode();
      window.removeEventListener('message',receiveMessage);
    });

    return {
      isMobile, coldcall, hrd, histories, lrnObjDtlDistSn,
      // concentrationModeText,
      goBackHrd, goBack, getVideoUrl, handleActions, showLatestHistory,
      // detailFuncs,
      session, iframeViewer,
      shareModal, historyModal, opinionModal, completeModal, latestHistoryModal,
      toggleScenario, toggleConcentrationMode, scoreBoard, localMessage
    }
  }
};
</script>